import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async getBooking(accessToken, booking_id) {
		let res = await axios.get(API_NAME + "/bookings/" + booking_id, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getProcessedBookings(accessToken, week, year) {
		let res = await axios.get(API_NAME + "/bookings/processed?week=" + week + "&year=" + year + "&summary=1", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getPayrollChecks(accessToken, week, year) {
		let res = await axios.get(API_NAME + "/bookings/payroll_checks?week=" + week + "&year=" + year , {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getClientInvoices(accessToken, week, year) {
		let res = await axios.get(API_NAME + "/bookings/processed?week=" + week + "&year=" + year + "&summary=1&client_invoices=1", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  
async saveBooking(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/bookings",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveBlockBooking(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/block_bookings",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveWard(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/wards",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async savePatient(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/patients",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async uploadTimesheet(accessToken, booking_id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + booking_id + "/upload_timesheet",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
     
    return res.data;
  },
  
  async updateBooking(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateBookingDetails(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + id + '/update',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateClientGroup(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/client_groups/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveClient(accessToken,  formdata) {
    let res = await axios.post(API_NAME + "/clients" ,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateClient(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/clients/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
  async updateMemberAlert(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/member_alerts/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async submitBookingTimesheet(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + id + "/submit_timesheet", formdata, 
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async approveBookingTimesheet(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + id + "/approve_timesheet", formdata, 
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async sendInvoice(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + id + "/send_invoice", formdata, 
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
  async processTimesheets(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/bookings/process_timesheets", formdata, 
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async processSingleTimesheet(accessToken, formdata, booking_id) {
    let res = await axios.post(API_NAME + "/bookings/" + booking_id + "/process_timesheet",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async reprocessTimesheet(accessToken, formdata, booking_id) {
    let res = await axios.post(API_NAME + "/bookings/" + booking_id + "/reprocess_timesheet",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async sendPayrollSummary(accessToken, formdata, member_id) {
    let res = await axios.post(API_NAME + "/members/" + member_id + "/send_payroll_summary",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async sendClientInvoices(accessToken, formdata, client_group_id) {
    let res = await axios.post(API_NAME + "/client_groups/" + client_group_id + "/send_client_invoices",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
  async sendAlerts(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + id + "/sendalerts",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },

  async cancelBooking(accessToken, booking_id, formdata) {
    let res = await axios.post(API_NAME + "/bookings/" + booking_id + "/cancel",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveBookingPatient(accessToken, formdata) {
		let res = await axios.post(API_NAME + "/booking_patients",  formdata,
			{
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
			
		  });
		  
		return res.data;
	  },
    async removeBookingPatient(accessToken, formdata) {
      let res = await axios.post(API_NAME + "/booking_patients/delete",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
        });
        
      return res.data;
      },
 

  async updateStone(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/stones/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateJob(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/jobs/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateDelivery(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/deliveries/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async setStoneInUse(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/stones/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
	async saveStoneType(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/stone_types",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveColour(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/colours",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveSupplier(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/suppliers",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveJob(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/jobs",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveDelivery(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/deliveries",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
}